import React from 'react'
import cn from 'classnames'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { BOARD_LIST } from '../urls'
import { useLoad } from '../hooks/request'
import { useModal } from '../hooks/modal'
import TaskBoardCreate from './TaskBoardCreate'
import { checkPermission as cp } from '../utils/auth'

export default function TaskBoardTabs({ project, board = null }) {
    const boards = useLoad({
        url: BOARD_LIST,
        params: { project: project.id, isActive: true },
    }, [], cp('task.view_board'))
    const { path } = useRouteMatch()

    const [showCreateModal, hideCreateModal] = useModal(
        <TaskBoardCreate
            project={project}
            onSuccess={() => hideCreateModal()}
            onCancel={() => hideCreateModal()} />,
    )

    return (
        <div className="tabs">
            <ul>
                <li>
                    <NavLink to="/task/projects" className="pl-0">
                        <i className="icon ion-md-arrow-back is-size-5 ml-0" />
                        <b>{project.name}</b>
                    </NavLink>
                </li>
                <li className={cn({ 'is-active': path.endsWith('/backlog') })}>
                    <NavLink to={`/task/projects/${project.id}/backlog`} className="pl-1">
                        <i className="ion-md-list icon ml-0" />
                        Все задачи &nbsp;
                    </NavLink>
                </li>

                {cp('task.view_board') && boards.response ? boards.response.results.map((item) => (
                    <li key={item.id} className={cn({ 'is-active': board && board.id === item.id })}>
                        <NavLink to={`/task/projects/${project.id}/board/${item.id}`}>
                            <i className="ion-md-list-box icon rotate-90" />
                            {board && board.id === item.id ? board.name : item.name}
                        </NavLink>
                    </li>
                )) : null}

                {cp('task.add_board') ? (
                    <li className={cn({ 'is-active': false })}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={showCreateModal} className="has-text-grey-light">
                        + Новый спринт
                        </a>
                    </li>
                ) : null}
            </ul>

            <li className={cn({ 'is-active': path.endsWith('/all-boards/') })}>
                <NavLink to={`/task/projects/${project.id}/all-boards/`} className="pl-1">
                    <i className="ion-md-list icon ml-0" />
                    Все спринты &nbsp;
                </NavLink>
            </li>
        </div>
    )
}
