import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ProtectedRoute from './components/common/ProtectedRoute'
import ConfirmEmail from './pages/ConfirmEmail'
import NotFound from './pages/NotFound'
import Login from './pages/Login'
import Transaction from './pages/Transaction'
import Wallet from './pages/Wallet'
import Employee from './pages/Employee'
import BaseContextWrapper from './components/common/BaseContext'
import StatisticsFinance from './pages/StatisticsFinance'
import TaskProjects from './pages/TaskProjects'
import EmployeeDetail from './pages/EmployeeDetail'
import ResetPassword from './pages/ResetPassword'
import ResetLink from './pages/ResetLink'
import EmailConfirmationMessage from './pages/EmailConfirmationMessage'
import SettingsUser from './pages/SettingsUser'
import SettingsFinance from './pages/SettingsFinance'
import ResetPasswordMessage from './pages/ResetPasswordMessage'
import { getEnv } from './utils/environment'
import SettingsMarketing from './pages/SettingsMarketing'
import Leads from './pages/Leads'
import Projects from './pages/Projects'
import Customers from './pages/Customers'
import Assets from './pages/Assets'
import TaskProjectBoard from './pages/TaskProjectBoard'
import InvitationSignUp from './pages/InvitationSignUp'
import TaskProjectBoards from './pages/TaskProjectBoards'
import DevelopersLeaderBoard from './pages/DevelopersLeaderBoard'
import IncomeTax from './pages/IncomeTax'
import UploadIncomeTax from './pages/UploadIncomeTax'
import IncomeQuarterly from './pages/IncomeQuarterly'
import UploadIncomeQuarterly from './pages/UploadIncomeQuarterly'
import Finance from './pages/Finance'
import UploadRotation from './pages/UploadRotation'
import QqcMonthly from './pages/QqcMonthly'
import UploadQqcMonthly from './pages/UploadQqcMonthly'
import QqcQuarterly from './pages/QqcQuarterly'
import UploadQqcQuarterly from './pages/UploadQqcQuarterly'
import Telegram from './pages/Telegram'
import Rotation from './pages/Rotation'
import CrmFinance from './pages/CrmFinance'
import ManthlyUploadIncomeTax from './pages/ManthlyUploadIncomeTax'
import ManthlyIncomeQuarterly from './pages/ManthlyIncomeQuarterly'
import ManthlyUploadIncomeQuarterly from './pages/ManthlyUploadIncomeQuarterly'
import ManthlyRotation from './pages/ManthlyRotation'
import ManthlyUploadRotation from './pages/ManthlyUploadRotation'
import ManthlyQqcMonthly from './pages/ManthlyQqcMonthly'
import ManthlyUploadQqcMonthly from './pages/ManthlyUploadQqcMonthly'
import ManthlyQqcQuarterly from './pages/ManthlyQqcQuarterly'
import ManthlyUploadQqcQuarterly from './pages/ManthlyUploadQqcQuarterly'
import ManthlyFinance from './pages/ManthlyFinance'
import ManthlyIncomeTax from './pages/ManthlyIncomeTax'
import TaskProjectBacklog from './pages/TaskProjectBacklog'


export default function App() {
    useEffect(() => {
        if (getEnv() === 'production') return
        document.title = `${getEnv().toUpperCase()} ${document.title}`
        document.querySelector("link[rel='shortcut icon']").href = `/${getEnv()}.favicon.png`
    }, [])

    return (
        <BrowserRouter>
            <BaseContextWrapper>
                <Switch>
                    <Route path="/" component={Login} exact />
                    <Route path="/reset-password/:key" component={ResetPassword} exact />
                    <Route path="/reset-link" component={ResetLink} exact />
                    <Route path="/confirm/:confirmationCode" component={ConfirmEmail} exact />
                    <Route path="/email-confirmation-message" component={EmailConfirmationMessage} exact />
                    <Route path="/reset-password-message" component={ResetPasswordMessage} exact />
                    <Route path="/invitation/:code" component={InvitationSignUp} exact />
                    <Route path="/task/projects/:projectId/all-boards/" component={TaskProjectBoards} exact />
                    <Route path="/leaderboard" component={DevelopersLeaderBoard} exact />
                    <ProtectedRoute path="/finance" component={Finance} exact />
                    <ProtectedRoute path="/finance/wallet" component={Wallet} exact />
                    <ProtectedRoute path="/finance/asset" component={Assets} exact />
                    <ProtectedRoute path="/finance/wallet/:walletId/transaction" component={Transaction} exact />
                    <ProtectedRoute path="/statistics/finance" component={StatisticsFinance} exact />
                    <ProtectedRoute path="/settings/user" component={SettingsUser} exact />
                    <ProtectedRoute path="/settings/finance" component={SettingsFinance} exact />
                    <ProtectedRoute path="/settings/marketing" component={SettingsMarketing} exact />
                    <ProtectedRoute path="/task/projects" component={TaskProjects} exact />
                    <ProtectedRoute path="/task/projects/:projectId/board/:id" component={TaskProjectBoard} exact />
                    <ProtectedRoute path="/task/projects/:id/backlog" component={TaskProjectBacklog} exact />
                    <ProtectedRoute path="/staff/employee" component={Employee} exact />
                    <ProtectedRoute path="/staff/employee/:employeeId" component={EmployeeDetail} exact />
                    <ProtectedRoute path="/project/leads" component={Leads} exact />
                    <ProtectedRoute path="/project/projects" component={Projects} exact />
                    <ProtectedRoute path="/zero/income-tax" component={IncomeTax} exact />
                    <ProtectedRoute path="/hello" component={CrmFinance} exact />
                    <ProtectedRoute path="/zero/income-tax-item/:incomeTaxId" component={UploadIncomeTax} exact />
                    <ProtectedRoute path="/zero/income-quarterly" component={IncomeQuarterly} exact />
                    <ProtectedRoute path="/zero/income-quarterly-item/:incomeQuarterlyId"
                        component={UploadIncomeQuarterly} exact />
                    <ProtectedRoute path="/zero/clients" component={Finance} exact />
                    <ProtectedRoute path="/zero/rotation" component={Rotation} exact />
                    <ProtectedRoute path="/zero/rotation-item/:rotationId" component={UploadRotation} exact />
                    <ProtectedRoute path="/zero/qqc-monthly" component={QqcMonthly} exact />
                    <ProtectedRoute path="/zero/qqc-monthly-item/:qqcMonthlyId" component={UploadQqcMonthly} exact />
                    <ProtectedRoute path="/zero/qqc-quarterly" component={QqcQuarterly} exact />
                    <ProtectedRoute path="/zero/qqc-quarterly-item/:qqcQuarterlyId" component={UploadQqcQuarterly}
                        exact />
                    <ProtectedRoute path="/manthly/income-tax" component={ManthlyIncomeTax} exact />

                    <ProtectedRoute path="/manthly/income-tax-item/:incomeTaxId" component={ManthlyUploadIncomeTax} exact />
                    <ProtectedRoute path="/manthly/income-quarterly" component={ManthlyIncomeQuarterly} exact />
                    <ProtectedRoute path="/manthly/income-quarterly-item/:incomeQuarterlyId"
                        component={ManthlyUploadIncomeQuarterly} exact />
                    <ProtectedRoute path="/manthly/clients" component={ManthlyFinance} exact />
                    <ProtectedRoute path="/manthly/rotation" component={ManthlyRotation} exact />
                    <ProtectedRoute path="/manthly/rotation-item/:rotationId" component={ManthlyUploadRotation} exact />
                    <ProtectedRoute path="/manthly/qqc-monthly" component={ManthlyQqcMonthly} exact />
                    <ProtectedRoute path="/manthly/qqc-monthly-item/:qqcMonthlyId" component={ManthlyUploadQqcMonthly} exact />
                    <ProtectedRoute path="/manthly/qqc-quarterly" component={ManthlyQqcQuarterly} exact />
                    <ProtectedRoute path="/manthly/qqc-quarterly-item/:qqcQuarterlyId" component={ManthlyUploadQqcQuarterly}
                        exact />
                    <ProtectedRoute path="/telegram" component={Telegram} exact />
                    <ProtectedRoute path="/project/customers" component={Customers} exact />
                    <Route path="" component={NotFound} exact />
                </Switch>
            </BaseContextWrapper>
        </BrowserRouter>
    )
}
