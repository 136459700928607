import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from './common/Button'
import { usePutRequest } from '../hooks/request'
import { BOARD_DETAIL } from '../urls'
import { useModal } from '../hooks/modal'
import TaskBoardCreate from './TaskBoardCreate'

export default function TaskProjectBoardFinish({ project, board, onCancel, onSuccess }) {
    const history = useHistory()
    const updateBoard = usePutRequest({ url: BOARD_DETAIL.replace('{id}', board.id) })
    const [createNew, setCreateNew] = useState(true)
    const [moveTasks, setMoveTasks] = useState(true)

    const [showCreateModal, hideCreateModal] = useModal(
        <TaskBoardCreate
            project={project}
            moveTasksFrom={moveTasks ? board.id : undefined}
            onSuccess={() => hideCreateModal()}
            onCancel={() => hideCreateModal()} />,
    )

    async function handleFinnish() {
        const { success } = await updateBoard.request({ data: { ...board, isActive: false } })
        if (!success) return

        onSuccess()

        if (!createNew) {
            history.push(`/task/projects/${project.id}/backlog`)
            return
        }

        showCreateModal()
    }

    return (
        <div>
            <b>Вы действительно хотите завершить спринт?</b>

            <div className="my-3">
                <label className="checkbox">
                    <input type="checkbox"
                        onChange={({ target }) => {
                            setCreateNew(target.checked)
                            setMoveTasks(target.checked && moveTasks)
                        }}
                        checked={createNew} /> &nbsp;
                    Создать новый спринт
                </label>

                <label className="checkbox">
                    <input type="checkbox"
                        onChange={({ target }) => setMoveTasks(target.checked)}
                        checked={moveTasks}
                        disabled={!createNew} /> &nbsp;
                    Перенести все невыполненные задания на новый спринт
                </label>
            </div>

            <Button
                onClick={handleFinnish}
                loading={updateBoard.loading}
                text="Завершить"
                icon="ion-md-checkmark"
                disabled={updateBoard.loading}
                className="is-success" /> &nbsp;

            <Button
                onClick={onCancel}
                icon="ion-md-close"
                text="Отмена"
                disabled={updateBoard.loading}
                className="is-danger" />
        </div>
    )
}
