import React from 'react'
import cn from 'classnames'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import Layout from '../components/Layout'
import { PermissionDenied } from '../components/PermissionDenied'
import { checkPermission as cp } from '../utils/auth'

import { PROJECT_SIMPLE_DETAIL, TASK_DETAIL, TASK_LIST } from '../urls'
import { useInfiniteScroll, useLoad, usePutRequest } from '../hooks/request'
import Loader from '../components/common/Loader'
import TaskBoardTabs from '../components/TaskBoardTabs'
import Table from '../components/common/Table'
import TaskItem from '../components/TaskItem'
import { useModal } from '../hooks/modal'
import TaskCreate from '../components/TaskCreate'
import Button from '../components/common/Button'
import { useQueryParams } from '../hooks/queryString'

export default function TaskProjectBacklog() {
    const params = useParams()
    const history = useHistory()
    const location = useLocation()
    const { isCompleted = false } = useQueryParams()
    const project = useLoad({ url: PROJECT_SIMPLE_DETAIL.replace('{id}', params.id) })
    const tasks = useInfiniteScroll({ url: TASK_LIST, params: { project: params.id, isCompleted } })
    const taskUpdate = usePutRequest()

    const [showCreateModal, hideCreateModal] = useModal(project.response ? (
        <TaskCreate project={project.response} onSuccess={() => {
            hideCreateModal()
            tasks.request()
        }} onCancel={() => hideCreateModal()} />
    ) : null)

    function onUpdate(id, data) {
        const results = tasks.response.results.map((task) => (task.id === id ? data : task))
        tasks.setResponse({ ...tasks.response, results })
    }

    function onDelete(id) {
        const results = tasks.response.results.filter((task) => task.id !== id)
        tasks.setResponse({ ...tasks.response, results })
    }

    async function onCompleted(task) {
        await taskUpdate.request({
            url: TASK_DETAIL.replace('{id}', task.id),
            data: {
                ...task,
                isCompleted: true,
                assigned: task.assigned.id,
            },
        })
        await tasks.request()
    }

    if (project.loading || !project.response) {
        return (
            <Layout>
                <Loader center large />
            </Layout>
        )
    }

    if (!cp('task.view_task')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <TaskBoardTabs project={project.response} />

            <div className="columns">
                <div className="column">
                    <span
                        onClick={() => history.push(location.pathname)}
                        className={cn('is-size-4 pointer mr-5', { 'has-text-grey-light': isCompleted })}>
                        Задачи ({tasks.response ? tasks.response.activeCount : 0})
                    </span>

                    <span
                        onClick={() => history.push(`${location.pathname}?isCompleted=true`)}
                        className={cn('is-size-4 pointer', { 'has-text-grey-light': !isCompleted })}>
                        Выполненные ({tasks.response ? tasks.response.completedCount : 0})
                    </span>
                </div>

                <div className="column is-narrow">
                    {cp('task.add_task') ? (
                        <Button
                            onClick={showCreateModal}
                            text="Добавить"
                            icon="ion-md-add"
                            className="is-pulled-right is-link is-outlined" />
                    ) : null}
                </div>
            </div>

            <Table
                showEmptyMessage={false}
                items={tasks.response ? tasks.response.results : []}
                renderItem={(task) => (
                    <TaskItem
                        key={task.id}
                        task={task}
                        onDelete={onDelete}
                        onCompleted={() => onCompleted(task)}
                        onUpdate={(data) => onUpdate(task.id, data)} />
                )} />

            <div ref={tasks.ref} className="has-text-grey-light is-italic has-text-centered">
                {!tasks.hasMore && !tasks.loading && tasks.response && tasks.response.results.length !== 0
                    ? 'Загрузили все задачи' : ''}
            </div>

            <Loader show={tasks.loading} center large />

        </Layout>
    )
}
