import React from 'react'
import { StyleSheet } from 'aphrodite'
import { FINANCE_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { useMessage } from '../hooks/message'
import FinanceUpdate from './FinanceUpdate'
import { domain } from '../utils/request'
import Button from './componentsFinance/common/Button'
import FinanceInfo from './FinanceInfo'

export default function FinanceItem({ item, index, onDelete, onUpdate }) {
    const financeDelete = useDeleteRequest({ url: FINANCE_DETAIL.replace('{id}', item.id) })
    const [showUpdateModal, hideUpdateModal] = useModal(
        <FinanceUpdate finance={item} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
        styles.modal,
    )

    const [showMessage] = useMessage()

    async function deleteFinance() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await financeDelete.request()
        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onDelete()
    }

    const [showViewModal, hideViewModal] = useModal(
        <FinanceInfo lead={item}
            onSuccess={() => {
                hideViewModal()
            }}
            onCancel={() => hideViewModal()} />,
    )

    return (
        <tr>
            <td className="has-text-center pr-3 pl-3">
                {index + 1}
            </td>
            <td onClick={showViewModal} className="has-text-center pr-3 pl-3">
                {item.name}
            </td>
            <td className="has-text-center pr-3 pl-3">
                {item.stir}
            </td>
            <td className="has-text-center pr-3 pl-3">
                {item.directorName}
            </td>
            <td className="has-text-center pr-3 pl-3">
                {item.directorStir}
            </td>
            {/* <td className="has-text-center"> */}
            {/*    {item.bank} */}
            {/* </td> */}
            {/* <td className="has-text-center pr-3 pl-3"> */}
            {/*    {item.accountNumber} */}
            {/* </td> */}
            {/* <td className="has-text-center pr-3 pl-3"> */}

            {/*    {item.mfo} */}

            {/* </td> */}
            {/* <td className="has-text-center pr-3 pl-3"> */}
            {/*    {item.phoneNumber} */}
            {/* </td> */}
            {/* <td className="has-text-center pr-3 pl-3"> */}
            {/*    {item.inspector} */}
            {/* </td> */}
            {/* <td className="has-text-center"> */}
            {/*    <NavLink to="home" className="has-text-dark"> */}
            {/*        {item.login} */}
            {/*    </NavLink> */}
            {/* </td> */}
            {/* <td className="has-text-center pr-3 pl-3"> */}
            {/*    {position(item.type)} */}
            {/* </td> */}
            {/* <td className="has-text-center pr-3 pl-3"> */}
            {/*    {item.taxDate} */}
            {/* </td> */}
            {/* <td className="has-text-center pr-3 pl-3"> */}
            {/*    {Intl.NumberFormat().format(item.contractSum)} */}
            {/* </td> */}
            <td className="pr-6">
                <a href={domain + item.first} className="has-text-dark has-text-center">
                    <Button
                        icon="icon ion-md-download"
                        className="is-pulled-right is-black is-outlined"
                    />
                </a>
            </td>
            <td className="pr-6">
                <a href={domain + item.second} className="has-text-dark">
                    <Button
                        icon="icon ion-md-download"
                        className="is-pulled-right is-black is-outlined"
                    />
                </a>
            </td>
            <td className="has-text-center pr-3 pl-3">
                <Button
                    icon="icon ion-md-trash"
                    onClick={deleteFinance}
                    className="is-pulled-right is-danger is-outlined"
                />
            </td>
            <td className="has-text-center pr-3 pl-3">
                <Button
                    icon="icon ion-md-create"
                    onClick={showUpdateModal}
                    className="is-pulled-right is-success is-outlined"
                />
            </td>
        </tr>
    )
}


const styles = StyleSheet.create({
    modal: {
        width: 800,
    },
})
