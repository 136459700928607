import { Form, Formik } from 'formik'
import React from 'react'
import find from 'lodash/find'
import { max, min, required, validator } from '../utils/validators'
import { developersLevel, positions } from '../utils/position'
import Input from './common/Input'
import Button from './common/Button'
import MultiSelect from './common/MultiSelect'
import Select from './common/Select'

export default function EmployeeForm({ onSubmit, onCancel, loading, initialValues }) {
    const options = Object.entries(positions).map(([value, label]) => ({ value, label }))

    return (
        <Formik initialValues={{
            name: '',
            birthday: '',
            salary: 0,
            positions: [],
            level: '',
            teamLeadShare: 0,
            sellerShare: '',
            ...initialValues,
        }} onSubmit={onSubmit}>
            {({ values }) => (
                <Form>
                    <MultiSelect
                        validate={required}
                        label="Должность"
                        name="positions"
                        placeholder="Введите должности сотрудника"
                        options={options} />

                    {find(values.positions, { value: 'developer' }) ? (
                        <Select
                            empty
                            name="level"
                            label="Уровень"
                            disabled={loading}
                            validate={required}
                            options={developersLevel} />
                    ) : null}

                    <Input
                        label="Имя"
                        name="name"
                        disabled={loading}
                        validate={required}
                        placeholder="Введите имя сотрудника" />

                    <Input
                        name="birthday"
                        type="date"
                        label="День рождения"
                        disabled={loading}
                        validate={required}
                        placeholder="Введите день рождение сотрудника" />

                    {find(values.positions, { value: 'team_lead' }) ? (
                        <Input
                            name="teamLeadShare"
                            type="number"
                            label="Доля лидер команды"
                            disabled={loading}
                            validate={validator(required, max(100), min(0))}
                            placeholder="Введите долю учителя" />
                    ) : null}

                    <Input
                        name="sellerShare"
                        label="Доля от продаж"
                        type="number"
                        disabled={loading}
                        validate={validator(required, max(100), min(0))}
                        placeholder="Введите долю продавца" />

                    <Input
                        name="salary"
                        label="Фиксированная зарплата"
                        type="number"
                        disabled={loading}
                        validate={validator(required, min(0))}
                        placeholder="Введите зарплату сотрудника" />

                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
