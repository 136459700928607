import React, { useState } from 'react'
import xor from 'lodash/xor'
import Button from './common/Button'
import { useInfiniteScroll } from '../hooks/request'
import { TASK_LIST } from '../urls'
import Table from './common/Table'
import Loader from './common/Loader'

export default function TaskBoardAdd({ project, board, onCancel, onAdd, loading }) {
    const tasks = useInfiniteScroll({ url: TASK_LIST, params: { project: project.id, excludeBoard: board.id } })
    const [selectedTasks, setSelectedTasks] = useState([])

    function toggleTask(id) {
        setSelectedTasks(xor(selectedTasks, [id]))
    }

    return (
        <div>
            <b>Выберите задачи которых вы хотите добавить</b>
            <div className="my-3">
                <Table
                    showEmptyMessage={false}
                    items={tasks.response ? tasks.response.results : []}
                    renderItem={(item) => (
                        <tr onClick={() => toggleTask(item.id)} key={item.id}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedTasks.includes(item.id)}
                                    onChange={() => toggleTask(item.id)} /> &nbsp;
                                {item.title}
                            </td>
                        </tr>
                    )} />

                <Loader show={tasks.loading} center large />

                <div ref={tasks.ref} className="has-text-grey-light is-italic has-text-centered">
                    {!tasks.hasMore && !tasks.loading && tasks.length !== 0 ? 'Загрузили все задачи' : ''}
                </div>
            </div>

            <Button
                onClick={() => onAdd(selectedTasks)}
                loading={loading}
                text={`Добавить выбранные - (${selectedTasks.length})`}
                icon="ion-md-checkmark"
                disabled={loading}
                className="is-success"/> &nbsp;

            <Button
                onClick={onCancel}
                icon="ion-md-close"
                text="Отмена"
                disabled={loading}
                className="is-danger"/>
        </div>
    )
}
