import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import Input from './componentsFinance/common/Input'
import Button from './componentsFinance/common/Button'
import Checkbox from './common/Checkbox'

export default function QqcQuarterlyItemForm({ onCancel, loading, initialValues, qqcQuarterlyId, finance, onSubmit }) {
    const [images, setImages] = useState({})

    async function onSuccess(data) {
        const newData = new FormData()

        newData.append('qqcQuarterlyPdf', images.qqcQuarterlyPdf)
        newData.append('finance', finance.id)
        newData.append('qqcQuarterly', qqcQuarterlyId)
        newData.append('qqcQuarterlyComment', data.qqcQuarterlyComment)
        newData.append('tax', data.tax)
        newData.append('inps', data.inps)

        onSubmit(newData)
    }

    return (
        <Formik onSubmit={onSuccess} initialValues={{
            qqcQuarterlyComment: '',
            tax: false,
            inps: false,
            ...initialValues,
        }}>
            <Form>
                <div className="columns">
                    <div className="column">
                        <Input
                            name="qqcQuarterlyComment"
                            label="Комментарий"
                            disabled={loading}
                            placeholder="Комментарий" />
                    </div>

                </div>

                <Checkbox
                    name="tax"
                    label="Комментарий"
                    disabled={loading}
                    placeholder="Комментарий" />

                <Checkbox
                    name="inps"
                    label="ИНПС реестр"
                    disabled={loading}
                    placeholder="Комментарий" />


                <div className="columns">
                    <div className="column">
                        <p className="level-left">
                            ҚҚС Кварталлик
                        </p>

                        <input
                            onChange={(e) => setImages({ ...images, qqcQuarterlyPdf: e.target.files[0] })}
                            type="file"
                            disabled={loading} />
                    </div>

                </div>

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
