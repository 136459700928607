import React from 'react'
import { BOARD_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import TaskBoardForm from './TaskBoardForm'

export default function TaskBoardUpdate({ onCancel, onUpdate, board }) {
    const boardUpdate = usePutRequest({ url: BOARD_DETAIL.replace('{id}', board.id) })

    async function onSubmit(data, actions) {
        const { response } = await boardUpdate.request({ data })
        actions.resetForm()
        onUpdate(response)
    }

    return (
        <div>
            <b>Изменить Доску</b><br /><br />

            <TaskBoardForm
                initialValues={board}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={boardUpdate.loading} />
        </div>
    )
}
