import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../components/common/Button'
import Input from '../components/common/Input'
import ServerError from '../components/common/ServerError'
import { useLoad, usePostRequest } from '../hooks/request'
import { INVITATION_SIGNUP } from '../urls'
import { maxLength, minLength, required, validator } from '../utils/validators'
import { signin } from '../utils/auth'
import invite from '../static/invite.svg'
import brokenInvitationCode from '../static/broken_invitation_code.svg'
import Loader from '../components/common/Loader'


export default function InvitationSignUp() {
    const history = useHistory()
    const { code } = useParams()
    const url = INVITATION_SIGNUP.replace('{code}', code)
    const invitation = useLoad({ url, headers: {} })
    const signUp = usePostRequest({ url, headers: {} })

    async function onSubmit(data) {
        const { response, success } = await signUp.request({ data })

        if (success) {
            signin(response, history)
        }
    }

    if (invitation.loading) {
        return (
            <div className={css(styles.container)}>
                <Loader center large />
            </div>
        )
    }

    if (invitation.error && invitation.error.status === 404) {
        return (
            <div className={css(styles.container)}>
                <img src={brokenInvitationCode} alt="Broken invitation link" className={css(styles.image)} />
                <br /><br />
                <h1 className="title" align="center">Ссылка устарела либо не существует</h1>
            </div>
        )
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={{ password: '' }}>
            <Form className={css(styles.container)}>
                <img src={invite} alt="Invitation" className={css(styles.image)} />
                <div className="columns">
                    <h1 className="column title">Придумайте новый пароль</h1>
                </div>

                <ServerError error={signUp.error} />
                <Input
                    name="password"
                    type="password"
                    validate={validator(required, maxLength(100), minLength(8))}
                    placeholder="*********" />

                <div className="field">
                    <p className="control">
                        <Button loading={signUp.loading} className="is-link is-block" text="Войти" type="submit" />
                    </p>
                </div>
            </Form>
        </Formik>
    )
}

const styles = StyleSheet.create({
    container: {
        margin: '10rem auto',
        width: '30rem',
    },
    image: {
        margin: '0 auto',
        width: '25rem',
        display: 'block',
    },
})
