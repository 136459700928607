import React, { useState } from 'react'
import * as queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import Button from '../components/common/Button'
import { useQueryParams } from '../hooks/queryString'
import { useModal } from '../hooks/modal'
import { useLoad } from '../hooks/request'
import { MANTHLY_INCOME_QUARTERLY_LIST, USERS_LIST } from '../urls'
import Layout from '../components/Layout'
import ManthlyTabs from '../components/ManthlyTabs'
import ManthlyIncomeQuarterlyItem from '../components/ManthlyIncomeQuarterlyItem'
import ManthlyIncomeQuarterlyCreate from '../components/ManthlyIncomeQuarterlyCreate'
import Loader from '../components/common/Loader'
import Table from '../components/common/Table'
import Pagination from '../components/common/Pagination'
import UserSelect from '../components/componentsFinance/common/UserSelect'

export default function ManthlyIncomeQuarterly() {
    const params = useQueryParams()
    const [selectCountry, setSelectCountry] = useState()
    const report = useLoad({
        url: MANTHLY_INCOME_QUARTERLY_LIST,
        params: { user: selectCountry, page: params.page },
    }, [params.page, selectCountry])
    const reportItem = report.response ? report.response.results : []
    const count = report.response ? report.response.count : []

    const history = useHistory()
    const [showModal, hideModal] = useModal(
        <ManthlyIncomeQuarterlyCreate
            onSuccess={() => {
                hideModal()
                report.request()
            }}
            onCancel={() => hideModal()} />,
    )

    const users = useLoad({ url: USERS_LIST })
    const userItem = users.response ? users.response.results : []
    const userOptions = Object.entries(userItem).map(([, {
        id,
        nickname,
    }]) => ({
        value: nickname,
        name: id,
    }))

    return (
        <Layout>
            <ManthlyTabs />
            <div className="columns mt-3 mr-3">
                <div className="column is-capitalized ml-4">
                    <span className="is-size-4">Фойда солиғи:</span>
                </div>
                <div className="column mt-3">
                    <div className="columns is-pulled-right">
                        <Formik initialValues={{ user: '' }}>
                            <Form className="mr-3">
                                <UserSelect
                                    emptyMessage="Ходимлар"
                                    empty
                                    onChange={setSelectCountry}
                                    options={userOptions}
                                    name="user"
                                />
                            </Form>
                        </Formik>
                        <Button
                            icon="ion-md-calendar"
                            text="Янги ой қўшиш"
                            onClick={showModal}
                            className="button is-success is-outlined"
                        />
                    </div>
                </div>
            </div>

            {!report.loading ? (
                <Table
                    loading={reportItem.loading}
                    items={reportItem}
                    columns={{
                        id: '№',
                        name: 'Ой номи',
                        stir: 'Мижозлар',
                        directorStir: 'Ҳисоботлар жўнатилиши',
                        ssuploadRotation: 'Солиқлар тўланиши',
                        xdirectorStir: 'ИНПС реестр жўнатолиши',
                        ad: '',
                        uploadRotation: '',
                        assd: '',


                    }}
                    renderItem={(item, index) => (
                        <ManthlyIncomeQuarterlyItem
                            reportItem={item}
                            index={index}
                            key={item.id}
                            onUpdate={report.request}
                            onDelete={report.request}
                            request={report.request}
                            reportItems={reportItem.response ? reportItem.response.results : []}
                            // incomeTaxId={incomeTaxId}
                            finance={item} />
                    )} />
            ) : <Loader center large />}


            <section className="section">
                <Pagination
                    onSelect={(page) => {
                        history.push(`/manthly/income-quarterly?${queryString.stringify({
                            ...page,
                            page,
                        })}`)
                    }}
                    page={params.page === undefined ? 1 : params.page}
                    count={count} />
            </section>


        </Layout>
    )
}
