import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import { PermissionDenied } from '../components/PermissionDenied'
import { checkPermission as cp } from '../utils/auth'
import { useInfiniteScroll, useLoad } from '../hooks/request'
import { BOARD_DETAIL, BOARD_TASK_LIST, PROJECT_SIMPLE_DETAIL } from '../urls'
import Loader from '../components/common/Loader'
import TaskBoard from '../components/TaskBoard'
import { taskStatuses } from '../utils/types'
import TaskBoardTabs from '../components/TaskBoardTabs'
import TaskProjectBoardActions from '../components/TaskProjectBoardActions'

export default function TaskProjectBoard() {
    const params = useParams()
    const tasks = useInfiniteScroll({ url: BOARD_TASK_LIST.replace('{board_id}', params.id) })
    const project = useLoad({ url: PROJECT_SIMPLE_DETAIL.replace('{id}', params.projectId) })
    const board = useLoad({ url: BOARD_DETAIL.replace('{id}', params.id) })

    if (project.loading || !project.response || board.loading || !board.response) {
        return (
            <Layout>
                <Loader center large />
            </Layout>
        )
    }

    if (!cp('task.view_boardtask') || !cp('task.view_board')) {
        return <PermissionDenied />
    }

    function updateTotalPoints(diff) {
        board.setResponse({
            ...board.response,
            totalPoints: board.response.totalPoints + diff,
        })
    }

    return (
        <Layout className={css(styles.header)}>
            <TaskBoardTabs project={project.response} board={board.response} />

            <div className="columns">
                <div className="column">
                    <span className="is-size-4">Спринт</span> &nbsp; &nbsp;

                    <span className="has-text-grey-light">
                        Сложность: {board.response ? board.response.totalPoints : 0}
                    </span>
                </div>

                <div className="column is-narrow">
                    <TaskProjectBoardActions
                        onUpdate={board.setResponse}
                        project={project.response}
                        board={board.response}
                        tasks={tasks} />
                </div>
            </div>

            <TaskBoard
                project={project.response}
                board={board.response}
                onPointsUpdate={updateTotalPoints}
                columns={taskStatuses}
                tasks={tasks} />
        </Layout>
    )
}

const styles = StyleSheet.create({
    header: {
        background: 'whitesmoke',
        zIndex: 1,
        position: 'sticky',
        top: '0',
    },
})
