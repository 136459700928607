import React from 'react'
import { Form, Formik } from 'formik'
import { usePostRequest } from '../hooks/request'
import { TASK_LIST } from '../urls'
import Input from './common/Input'
import { required } from '../utils/validators'
import Button from './common/Button'

export default function TaskCreate({ project, onCancel, onSuccess, loading }) {
    const taskCreate = usePostRequest({ url: TASK_LIST })

    async function onSubmit(data, actions) {
        const { response } = await taskCreate.request({ data: { ...data, project: project.id } })
        actions.resetForm()
        onSuccess(response.id)
    }

    return (
        <div>
            <b>Добавить новую задачу</b><br /><br />

            <Formik initialValues={{ title: '', description: '' }} onSubmit={onSubmit}>
                <Form>
                    <Input
                        name="title"
                        label="Заголовок"
                        placeholder="Введите заголовок задачи"
                        disabled={taskCreate.loading || loading}
                        validate={required} />

                    <Input name="description" optional component="textarea" label="Описание" />
                    <br />

                    <Button
                        loading={taskCreate.loading || loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        disabled={taskCreate.loading || loading}
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        disabled={taskCreate.loading || loading}
                        className="is-danger" />
                </Form>
            </Formik>
        </div>
    )
}
