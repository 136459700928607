import React from 'react'
import cn from 'classnames'
import Button from './componentsFinance/common/Button'
import { useModal } from '../hooks/modal'
import { domain } from '../utils/request'
import IncomeQuarterlyItemCreate from './IncomeQuarterlyItemCreate'
import IncomeQuarterlyItemUpdate from './IncomeQuarterlyItemUpdate'


export default function IncomeQuarterlyItemItem({ reportItems, finance, incomeQuarterlyId, index, request }) {
    const reportItem = reportItems.filter((item) => item.finance === finance.id)[0]
    const [showModal, hideModal] = useModal(
        <IncomeQuarterlyItemCreate
            onSuccess={() => {
                hideModal()
                request()
            }}
            incomeQuarterlyId={incomeQuarterlyId}
            finance={finance}
            onCancel={() => hideModal()} />,
    )
    const [showUpdateModal, hideUpdateModal] = useModal(
        <IncomeQuarterlyItemUpdate
            onSuccess={() => {
                hideUpdateModal()
                request()
            }}
            reportItem={reportItem}
            incomeQuarterlyId={incomeQuarterlyId}
            finance={finance}
            onCancel={() => hideModal()} />,
    )
    function onSubmit() {
        if (reportItem) {
            showUpdateModal()
            return
        }
        showModal()
    }

    return (
        <tr className="pl-3 pr-3">
            <td className="has-text-centered">
                {index + 1}
            </td>

            {reportItem ? (
                <td className={cn('has-text-centered has-text-black')}>
                    {finance.name}
                </td>
            ) : (
                <td className={cn('has-text-centered has-text-danger')}>
                    {finance.name}
                </td>
            )}

            <td className="has-text-centered">
                {finance.stir}
            </td>
            <td className="has-text-centered">
                {finance.directorStir}
            </td>

            {reportItem ? (
                <>
                    <td className="has-text-centered">
                        <a className={reportItem ? 'is-link is-light' : 'is-link is-light'}
                            href={domain + reportItem.incomeQuarterlyPdf}>
                            <Button
                                icon="icon ion-md-download"
                                className="is-pulled-right is-outlined is-link is-light"
                            />
                        </a>
                    </td>

                    <td className="has-text-centered">
                        {reportItem.incomeQuarterlyComment}
                    </td>

                </>
            ) : (
                <>
                    <td className="has-text-centered">
                        Пусто
                    </td>
                    <td className="has-text-centered">
                        Пусто
                    </td>
                </>
            )}
            <td className="has-text-centered">
                <input type="checkbox" checked={reportItem ? reportItem.tax : false} />
            </td>


            <td>
                <Button
                    onClick={onSubmit}
                    className={reportItem ? 'button is-success is-light' : 'button is-danger is-light'}
                    icon={reportItem ? 'icon ion-md-create' : 'icon ion-md-add'}
                    text={reportItem ? 'Обновить' : 'Добавить'}
                />
            </td>
        </tr>
    )
}
