import React from 'react'
import TaskPoints from './TaskPoints'
import { useModal } from '../hooks/modal'
import TaskDetails from './TaskDetails'

export default function TaskItem({ task, onUpdate, onDelete, onCompleted }) {
    const [showDetailsModal, hideDetailsModal] = useModal(
        <TaskDetails
            onDelete={(id) => {
                onDelete(id)
                hideDetailsModal()
            }}
            onCompleted={() => {
                onCompleted()
                hideDetailsModal()
            }}
            onUpdate={onUpdate}
            task={task} />,
    )

    return (
        <tr onClick={showDetailsModal} className="pointer">
            <td className="is-narrow">
                <p className="has-text-grey">#{task.id}</p>
            </td>

            <td className="px-4 py-3">{task.title}</td>

            <td className="is-narrow">
                <TaskPoints points={task.points} />
            </td>
        </tr>
    )
}
