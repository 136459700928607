import cn from 'classnames'
import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Draggable } from 'react-beautiful-dnd'
import { checkPermission as cp } from '../utils/auth'
import TaskPoints from './TaskPoints'
import Avatar from './Avatar'
import { useModal } from '../hooks/modal'
import TaskDetails from './TaskDetails'

export default function TaskBoardCard({ task, board, index, onUpdate, onDelete, onPointsUpdate, onCompleted }) {
    const [showDetailsModal, hideDetailsModal] = useModal(
        <TaskDetails
            task={task.task}
            boardTask={task}
            board={board}
            onUpdate={onUpdate}
            onPointsUpdate={onPointsUpdate}
            onCompleted={() => {
                onCompleted(task.id)
                hideDetailsModal()
            }}
            onDelete={(id) => {
                onDelete(id)
                hideDetailsModal()
            }} />,
    )

    return (
        <Draggable draggableId={String(task.id)} isDragDisabled={!cp('task.change_boardtask')} index={index}>
            {(provider, snap) => (
                <div
                    ref={provider.innerRef}
                    {...provider.draggableProps}
                    {...provider.dragHandleProps}
                    onClick={showDetailsModal}
                    className={cn('box', css(styles.card, snap.isDragging && styles.active))}
                    style={provider.draggableProps.style}>

                    <div className="columns">
                        <div className="column">
                            {Math.abs(new Date(task.createdAt) - new Date(board.createdAt)) / 3600000 > 2
                                ? <span className="tag is-success mr-2 is-normal is-light">new</span> : null}
                            <span className={css(styles.title)}>{task.task.title}</span>
                            <p className="has-text-grey-light">#{task.task.id}</p>
                        </div>

                        <div className="column is-narrow">
                            <TaskPoints points={task.task.points} className="mr-3" />
                            <Avatar employee={task.task.assigned} />
                            {/* <p className="has-text-grey-light">@{task.position}</p> */}
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    )
}

const styles = StyleSheet.create({
    card: {
        userSelect: 'none',
        marginBottom: '0.5rem',
        borderRadius: '3px',
    },
    title: {
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto',
        '@media (max-width: 769px)': {
            maxWidth: '30rem',
        },
    },
    active: {
        // drugging styles goes here
    },
})
