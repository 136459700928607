import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile'
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet'
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn'
import { Form, Formik } from 'formik'
import { useLoad, useSortTableData } from '../hooks/request'
import { MANTHLY_FINANCE_LIST, MANTHLY_INCOME_TAX_DETAIL, MANTHLY_INCOME_TAX_ITEM_LIST, USERS_LIST } from '../urls'
import Table from '../components/common/Table'
import Button from '../components/common/Button'
import Layout from '../components/Layout'
import ManthlyIncomeTaxItemItem from '../components/ManthlyIncomeTaxItemItem'
import UserSelect from '../components/componentsFinance/common/UserSelect'

export default function ManthlyUploadIncomeTax() {
    const { incomeTaxId } = useParams()
    const [selectCountry, setSelectCountry] = useState()
    const finances = useLoad({
        url: MANTHLY_FINANCE_LIST,
        params: { incomeTax: incomeTaxId, user: selectCountry },
    }, [selectCountry])
    const reportDetail = useLoad({ url: MANTHLY_INCOME_TAX_DETAIL.replace('{id}', incomeTaxId) })
    const reportItems = useLoad({ url: MANTHLY_INCOME_TAX_ITEM_LIST.replace('{incomeTaxId}', incomeTaxId) })
    const { items, requestSort, sortConfig } = useSortTableData(finances.response ? finances.response.results : [])
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return
        }
        return (
            sortConfig.key === name ? sortConfig.direction : undefined
        )
    }
    const users = useLoad({ url: USERS_LIST })
    const userItem = users.response ? users.response.results : []
    const userOptions = Object.entries(userItem).map(([, {
        id,
        nickname,
    }]) => ({
        value: nickname,
        name: id,

    }))

    return (
        <Layout>
            <div className="level">
                <div className="level-left">
                    <span className="title is-capitalized">
                        {reportDetail.response ? reportDetail.response.date : ''}
                    </span>
                </div>
                <div className="level-right">
                    <Formik initialValues={{ user: '' }}>
                        <Form className="mr-3">
                            <UserSelect
                                emptyMessage="Ходимлар"
                                empty
                                onChange={setSelectCountry}
                                options={userOptions}
                                name="user"
                            />
                        </Form>
                    </Formik>
                    <div className="is-pulled-right is-success is-outlined mr-3">
                        <ExcelFile
                            element={(
                                <Button
                                    icon="ion-md-download"
                                    text="Excel"
                                    className="is-pulled-right is-success is-outlined mr-3" />
                            )}
                            filename={reportDetail.response ? reportDetail.response.date : ''}>
                            <ExcelSheet name="Excel" data={finances.response ? finances.response.results : []}>
                                <ExcelColumn label="Корхона номи" value="name" />
                                <ExcelColumn label="Юридик стир" value="stir" />
                                <ExcelColumn label="Жисмоний стир" value="directorStir" />
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                    <Button
                        icon="ion-md-search"
                        text="Корхона номи"
                        className={cn(getClassNamesFor('name'),
                            'is-pulled-right is-success is-outlined mr-3')}
                        onClick={() => requestSort('name')} />
                    <Button
                        icon="ion-md-search"
                        text="Юридик стир"
                        onClick={() => requestSort('stir')}
                        className={cn(getClassNamesFor('stir'),
                            'is-pulled-right is-success is-outlined mr-3')} />
                    <Button
                        icon="ion-md-search"
                        text="Жисмоний стир"
                        onClick={() => requestSort('directorStir')}
                        className={cn(getClassNamesFor('directorStir'),
                            'is-pulled-right is-success is-outlined')} />
                </div>
            </div>

            <Table
                loading={finances.loading}
                items={items}
                columns={{
                    id: '№',
                    name: 'Мижоз корхона номи',
                    stir: 'Юридик стир',
                    directorStir: 'Жисмоний стир',
                    uploadRotation: 'Жисмоний Шахсланинг Даромад  Солиғи',
                    commentRotation: 'Изоҳ',
                    tax: 'Солиқ',
                    inps: 'ИНПС',
                    ad: '',

                }}
                renderItem={(item, index) => (
                    <ManthlyIncomeTaxItemItem
                        index={index}
                        key={item.id}
                        onUpdate={finances.request}
                        onDelete={finances.request}
                        request={reportItems.request}
                        reportItems={reportItems.response ? reportItems.response.results : []}
                        incomeTaxId={incomeTaxId}
                        finance={item} />
                )} />
        </Layout>
    )
}
