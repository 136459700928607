import React from 'react'
import Table from '../components/common/Table'
import TelegramItem from '../components/TelegramItem'
import { useLoad } from '../hooks/request'
import { TELEGRAM } from '../urls'
import Layout from '../components/componentsFinance/Layout'

export default function Telegram() {
    const telegramUsers = useLoad({ url: TELEGRAM })
    return (
        <Layout>
            {/* <div className="panel-block section container"> */}
            {/*    <table className="table is-bordered pricing__table"> */}
            {/*        <thead> */}
            {/*            <tr> */}
            {/*                <th>Full Name</th> */}
            {/*                <th>Phone Number</th> */}
            {/*                <th>Telegram username</th> */}
            {/*                <th>Location</th> */}
            {/*                <th>Time</th> */}
            {/*                <th>Status</th> */}
            {/*            </tr> */}
            {/*        </thead> */}

            {/*        <tbody> */}
            {/*            <tr> */}
            {/*                <td>Phone Number</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*            </tr> */}
            {/*            <tr> */}
            {/*                <td> */}
            {/*                    Extra Person <br /> */}
            {/*                    ( 12 yrs) */}
            {/*                </td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*            </tr> */}
            {/*            <tr> */}
            {/*                <td> */}
            {/*                    Extra Person <br /> */}
            {/*                    ( 12 yrs) */}
            {/*                </td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*            </tr> */}
            {/*            <tr> */}
            {/*                <td> */}
            {/*                    Extra Child */}
            {/*                    <br /> */}
            {/*                    (12 yrs) */}
            {/*                </td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*                <td>₹ 70,523.90</td> */}
            {/*            </tr> */}
            {/*            <tr> */}
            {/*                <td>Total Cost</td> */}
            {/*               90</td> */}
            {/*            </tr> */}
            {/*        </tbody> */}
            {/*    </table> */}
            {/* </div> */}

            <div className="section">
                <Table
                    items={telegramUsers.response ? telegramUsers.response.results : []}
                    columns={{
                        id: '№',
                        username: 'Фойдаланувчи номи',
                        car: 'Корхона рахбари\t',
                        number: 'Tелефон',
                        colorOfCar: 'Корхона номи',
                        numberOfCar: '',
                        lastActivity: 'lastActivity',
                        ae: '',
                    }}
                    renderItem={(item, index) => (
                        <TelegramItem
                            key={item.id}
                            index={index}
                            item={item} />
                    )} />

            </div>
        </Layout>
    )
}
