import React from 'react'
import { usePostRequest } from '../hooks/request'
import { MANTHLY_FINANCE_LIST } from '../urls'
import ManthlyFinanceForm from './ManthlyFinanceForm'

export default function ManthlyFinanceCreate({ onCancel, onSuccess }) {
    const financeCreate = usePostRequest({ url: MANTHLY_FINANCE_LIST })

    async function onSubmit(data) {
        const { success } = await financeCreate.request({ data })
        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Добавить</b><br /><br />
            <ManthlyFinanceForm onSubmit={onSubmit} onCancel={onCancel} loading={financeCreate.loading} />
        </div>
    )
}
