import React from 'react'
import FinanceForm from './FinanceForm'
import { FINANCE_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ServerError from './componentsFinance/common/ServerError'

export default function FinanceUpdate({ onCancel, onUpdate, finance }) {
    const url = FINANCE_DETAIL.replace('{id}', finance.id)
    const financeUpdate = usePutRequest({ url })
    async function onSubmit(data) {
        const { success } = await financeUpdate.request({ data })

        if (success) {
            onUpdate()
        }
    }

    return (
        <div>
            <b>Изменить данные о клиенты</b><br /><br />
            <ServerError error={financeUpdate.error} />
            <FinanceForm
                initialValues={finance}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={financeUpdate.loading} />
        </div>
    )
}
