import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import Layout from '../components/Layout'
import { useInfiniteScroll, useLoad } from '../hooks/request'
import { LEAD_LIST, LEAD_STATUS_LIST } from '../urls'
import { PermissionDenied } from '../components/PermissionDenied'
import { checkPermission as cp } from '../utils/auth'
import LeadBoard from '../components/LeadBoard'
import ProjectTabs from '../components/ProjectTabs'


export default function Leads() {
    const leads = useInfiniteScroll({ url: LEAD_LIST })
    const status = useLoad({ url: LEAD_STATUS_LIST })

    function onUpdate(leadId, data) {
        const results = isEmpty(data) ? (
            leads.response.results.filter((item) => item.id !== leadId)
        ) : (
            leads.response.results.map((item) => (
                item.id === leadId ? { ...item, ...data } : item
            ))
        )
        leads.setResponse({ count: leads.response.count, results })
    }

    if (!cp('project.view_lead')) {
        return <PermissionDenied />
    }

    return (
        <Layout className={css(styles.header)}>
            <ProjectTabs />

            <LeadBoard
                onUpdate={onUpdate}
                columns={status.response && status.response.results ? status.response.results : []}
                leads={leads}
                onReloadStatues={status.request} />
        </Layout>
    )
}


const styles = StyleSheet.create({
    header: {
        background: 'whitesmoke',
        zIndex: 1,
        position: 'sticky',
        top: '0',
    },
})
