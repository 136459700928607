import React from 'react'
import Button from './common/Button'
import { useModal } from '../hooks/modal'
import { checkPermission as cp } from '../utils/auth'
import TaskBoardAdd from './TaskBoardAdd'
import Dropdown, { DropdownItem } from './common/Dropdown'
import TaskProjectBoardFinish from './TaskProjectBoardFinish'
import TaskCreate from './TaskCreate'
import { usePostRequest } from '../hooks/request'
import { BOARD_TASK_LIST } from '../urls'
import TaskBoardUpdate from './TaskBoardUpdate'


export default function TaskProjectBoardActions({ project, board, onUpdate, tasks }) {
    const addTaskList = usePostRequest({ url: BOARD_TASK_LIST.replace('{board_id}', board.id) })

    async function handleAddTasks(data) {
        const { response } = await addTaskList.request({ data: { tasks: data } })
        addTasks(response)
    }

    const [showAddModal, hideAddModal] = useModal(
        <TaskBoardAdd
            board={board}
            project={project}
            loading={addTaskList.loading}
            onCancel={() => hideAddModal()}
            onAdd={(data) => {
                handleAddTasks(data)
                hideAddModal()
            }} />,
    )

    const [showUpdateModal, hideUpdateModal] = useModal(board ? (
        <TaskBoardUpdate
            board={board}
            onUpdate={(data) => {
                onUpdate(data)
                hideUpdateModal()
            }}
            onCancel={() => hideUpdateModal()} />
    ) : null)

    const [showFinnishModal, hideFinnishModal] = useModal(
        <TaskProjectBoardFinish
            board={board}
            project={project}
            onCancel={() => hideFinnishModal()}
            onSuccess={() => hideFinnishModal()} />,
    )

    const [showCreateModal, hideCreateModal] = useModal(
        <TaskCreate
            board={board}
            loading={addTaskList.loading}
            project={project}
            onCancel={() => hideCreateModal()}
            onSuccess={(data) => {
                hideCreateModal()
                handleAddTasks([data])
            }}
        />,
    )

    function addTasks(data) {
        const results = tasks.response.results.map((task) => {
            if (task.status !== 'todo') return task
            return { ...task, position: task.position + data.length }
        })
        tasks.setResponse({ results: [...results, ...data] })
    }

    return (
        <div className="field has-addons">
            {cp('task.add_boardtask') ? (
                <div className="control">
                    <Button
                        icon="ion-md-add"
                        onClick={showAddModal}
                        text="Добавить задания"
                        className="is-link is-outlined" />
                </div>
            ) : null}

            <div className="control">
                <Dropdown trigger={<Button icon="ion-md-more" className="is-link is-outlined" />} right>
                    {cp('task.add_boardtask') && cp('task.add_task') ? (
                        <DropdownItem
                            onClick={showCreateModal}
                            text="Добавить задачу"
                            icon="ion-md-add-circle" />
                    ) : null}

                    {cp('task.change_board') ? (
                        <DropdownItem
                            onClick={showFinnishModal}
                            text="Завершить спринт"
                            icon="ion-md-checkmark-circle" />
                    ) : null}

                    {cp('task.change_board') ? (
                        <DropdownItem
                            onClick={showUpdateModal}
                            text="Изменить спринт"
                            icon="ion-md-create" />
                    ) : null}
                </Dropdown>
            </div>
        </div>
    )
}
