import React from 'react'
import Button from './common/Button'
import { domain } from '../utils/request'
import { useLoad } from '../hooks/request'
import { USERS_DETAIL } from '../urls'


export default function ManthlyFinanceInfo({ lead, onCancel }) {
    const userDetail = useLoad({ url: USERS_DETAIL.replace('{id}', lead ? lead.user : 0) })
    const item = userDetail.response ? userDetail.response : {}
    return (
        <div>
            <b>Информация о Проекте: {lead.name}</b><br /><br />

            <table className="table is-striped is-fullwidth">
                <tbody>
                    <tr>
                        <td>Корхона СТИР:</td>
                        <td>
                            {lead.stir || <span className="has-text-grey">Пусто</span>}
                        </td>
                    </tr>

                    <tr>
                        <td>Корхона рахбари:</td>

                        <td>
                            {lead.directorName || <span className="has-text-grey">Пусто</span>}
                        </td>
                    </tr>


                    <tr>
                        <td>Корхона рахбари СТИР:</td>

                        <td>
                            {lead.directorStir || <span className="has-text-grey">Пусто</span>}
                        </td>
                    </tr>

                    <tr>
                        <td>Банк:</td>
                        <td>{lead.bank || <span className="has-text-grey">Пусто</span>}
                        </td>
                    </tr>

                    <tr>
                        <td>Х/р:</td>

                        <td>
                            {lead.accountNumber || <span className="has-text-grey">Пусто</span>}
                        </td>
                    </tr>

                    <tr>
                        <td>МФО:</td>
                        <td>{lead.mfo || <span className="has-text-grey">Пусто</span>}</td>
                    </tr>

                    <tr>
                        <td>Солик инспектори:</td>
                        <td>{lead.inspector || <span className="has-text-grey">Пусто</span>}</td>
                    </tr>

                    <tr>
                        <td>Хизмат кўрсатувчи банк оператори телефон:</td>
                        <td>{lead.phoneNumber || <span className="has-text-grey">Пусто</span>}</td>
                    </tr>
                    <tr>
                        <td>Интернет банк логин:</td>
                        <td>{lead.login || <span className="has-text-grey">Пусто</span>}</td>
                    </tr>
                    <tr>
                        <td>Солиқ тури:</td>
                        <td>{lead.type || <span className="has-text-grey">Пусто</span>}</td>
                    </tr>
                    <tr>
                        <td>ҚҚСга ўтган ой:</td>
                        <td>{lead.taxDate || <span className="has-text-grey">Пусто</span>}</td>
                    </tr>
                    <tr>
                        <td>Сумма контракта:</td>
                        <td>{lead.contractSum || <span className="has-text-grey">Пусто</span>}</td>
                    </tr>
                    <tr>
                        <td>Корхонанинг ЭРИ калити:</td>
                        <td>
                            <a href={`${domain + lead.first}`}>
                                <span className="has-text-grey"><Button
                                    icon="icon ion-md-download"
                                    className="is-pulled-right is-outlined is-link is-light"
                                />
                                </span>
                            </a>
                        </td>

                    </tr>
                    <tr>
                        <td>Раҳбарнинг ЭРИ калити:</td>
                        <td><a href={`${domain + lead.second}`}>
                            <span className="has-text-grey"><Button
                                icon="icon ion-md-download"
                                className="is-pulled-right is-outlined is-link is-light"
                            />
                            </span>
                            </a>
                        </td>

                    </tr>
                    <tr>
                        <td>Масул ходим:</td>
                        <td>{item.nickname || <span className="has-text-grey">Пусто</span>}</td>
                    </tr>

                </tbody>
            </table>

            <Button
                onClick={onCancel}
                icon="ion-md-close"
                text="Закрыть"
                className="is-danger" /> &nbsp;

        </div>
    )
}
