/* eslint-disable jsx-a11y/anchor-is-valid */
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import React, { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import TransactionCreate from '../components/TransactionCreate'
import TransactionItem from '../components/TransactionItem'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import { useLoad } from '../hooks/request'
import { TRANSACTION_LIST, WALLET_DETAIL } from '../urls'
import Table from '../components/common/Table'
import { useQueryParams } from '../hooks/queryString'
import { getMonth } from '../utils/date'
import { checkPermission as cp } from '../utils/auth'
import { PermissionDenied } from '../components/PermissionDenied'
import { format } from '../utils/number'
import TransactionSearch from '../components/TransactionSearch'
import TransactionTransfer from '../components/TransactionTransfer'

export default function Transaction({ history }) {
    const [showDropdown, setShowDropdown] = useState(false)
    const { walletId } = useParams()
    const params = useQueryParams()
    const wallet = useLoad({ url: WALLET_DETAIL.replace('{id}', walletId) })

    const transactions = useLoad({
        url: TRANSACTION_LIST,
        params: { ...params, wallet: walletId },
    }, [params.page])

    function reload() {
        transactions.request()
        wallet.request()
    }

    let walletBalance = 0
    if(!wallet.loading && wallet.response){
        walletBalance = wallet.response.balance
    }

    const [showCreateOutcomeModal, hideCreateOutcomeModal] = useModal(
        <TransactionCreate
        type="outcome"
        walletBalance={walletBalance}
        wallet={walletId}
        onCancel={() => hideCreateOutcomeModal()} onSuccess={() => {
            hideCreateOutcomeModal()
            reload()
        }} />,
    )

    const [showCreateTransferModal, hideCreateTransferModal] = useModal(
        <TransactionTransfer
            type="outcome"
            wallet={wallet.response}
            onCancel={() => hideCreateTransferModal()}
            onSuccess={() => {
                hideCreateOutcomeModal()
                reload()
            }} />,
    )

    // Create new Transaction Modal
    const [showCreateIncomeModal, hideCreateIncomeModal] = useModal(
        <TransactionCreate type="income" wallet={walletId} onCancel={() => hideCreateIncomeModal()} onSuccess={() => {
            hideCreateIncomeModal()
            reload()
        }} />,
    )

    if (!cp('finance.view_transaction')) {
        return <PermissionDenied />
    }


    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ search })}`)
    }

    return (
        <Layout>
            <div className="columns">
                <div className="column">
                    <p className="is-size-4">
                        <NavLink className="has-text-black button is-white" to="/finance/wallet">
                            <i className="icon ion-md-arrow-back is-size-4" />
                        </NavLink> &nbsp;

                        {wallet.response ? wallet.response.name : null}
                    </p>
                </div>

                <div className="columns is-mobile">
                    <div className="column is-narrow">
                        <TransactionSearch onSearch={onSearch} />
                    </div>

                    <div className="field has-addons column">
                        <p className="control">
                            {cp('finance.add_transaction') ? (
                                <Button
                                    text="Добавить расход"
                                    className="is-outlined is-link"
                                    icon="ion-md-add"
                                    onClick={showCreateOutcomeModal} />
                            ) : null}
                        </p>

                        <p className="control">
                            <span className={cn('dropdown is-right', { 'is-active': showDropdown })}
                                onMouseLeave={() => setShowDropdown(false)}>

                                <Button
                                    className="button dropdown-trigger is-outlined is-link"
                                    icon="ion-md-arrow-dropdown"
                                    onClick={() => setShowDropdown(!showDropdown)} />

                                <span className="dropdown-menu">
                                    <span className="dropdown-content is-pulled-left">
                                        {cp('finance.add_transaction') ? (
                                            <a className="dropdown-item" onClick={showCreateIncomeModal}>
                                                <i className="icon ion-md-add" />
                                                Добавить приход
                                            </a>
                                        ) : null}

                                        {cp('finance.add_transaction') ? (
                                            <a className="dropdown-item" onClick={showCreateTransferModal}>
                                                <i className="icon ion-md-swap" />
                                                Перевести на другой счет
                                            </a>
                                        ) : null}
                                    </span>
                                </span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            { !transactions.loading && !wallet.loading && wallet.response ? (
                <div className={css(styles.columns)}>
                    <div className="is-mobile column is-narrow">
                        Общая сумма

                        <p className="is-size-4">
                            {format(wallet.response.balance ? wallet.response.balance : 0)}
                        </p>
                    </div>

                    <div className="is-mobile column is-narrow">
                        Приход({getMonth()})

                        <p className="is-size-4 has-text-success">
                            {format(wallet.response.income ? wallet.response.income : 0)}
                        </p>
                    </div>

                    <div className="is-mobile column is-narrow">
                        Расход({getMonth()})

                        <p className="is-size-4 has-text-danger">
                            {format(wallet.response.outcome ? wallet.response.outcome : 0)}
                        </p>
                    </div>
                </div>
            ) : null }

            <Table
                loading={transactions.loading || wallet.loading}
                totalCount={transactions.response ? transactions.response.count : 0}
                columns={{
                    description: 'Описание',
                    amount: 'Сумма',
                    type: 'Тип',
                    createdBy: 'Кем создана',
                    createdAt: 'Дата создания',
                    actions: '',
                }}
                items={transactions.response ? transactions.response.results : []}
                renderItem={(transaction) => (
                    <TransactionItem
                        walletId={walletId}
                        reload={reload}
                        transaction={transaction}
                        key={transaction.id} />
                )} />
        </Layout>
    )
}

const styles = StyleSheet.create({
    columns: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '1.2rem 0',
    },
})
