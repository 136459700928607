import React from 'react'
import Layout from '../components/Layout'
import { useMessage } from '../hooks/message'
import { usePutRequest } from '../hooks/request'
import { CHANGE_PASSWORD, USER_SETTINGS } from '../urls'
import ManthlyTabs from '../components/ManthlyTabs'
// import UserForm from '../components/UserForm'
// import UserChangePassword from '../components/UserChangePassword'
// import Card from '../components/common/Card'
// import GitlabToken from '../components/GitlabToken'

export default function CrmFinance() {
    const updateUserSetting = usePutRequest({ url: USER_SETTINGS })
    const changePassword = usePutRequest({ url: CHANGE_PASSWORD })
    // const user = JSON.parse(localStorage.getItem('user'))
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        await updateUserSetting.request({ data })
        localStorage.setItem('user', JSON.stringify(data))
        showMessage('Информация изменен', 'is-success')
    }

    async function updatePassword(data, actions) {
        if (data.newPassword !== data.confirmPassword) {
            showMessage('Введенные пароли не совпадают', 'is-danger')
            return
        }

        const { error } = await changePassword.request({ data, newPassword: data.newPassword })
        if (error) {
            showMessage('Введете старый пароль правильно.', 'is-danger')
        } else {
            showMessage('Пароль изменен', 'is-success')
        }
        actions.resetForm()
    }

    return (
        <Layout className="has-background-light">
            <ManthlyTabs />

            {/* <div className="columns is-multiline"> */}
            {/*    <div className="column"> */}
            {/*        <Card> */}
            {/*            <UserForm user={user} onSubmit={onSubmit} /> */}
            {/*        </Card> */}
            {/*    </div>&nbsp; */}

            {/*    <div className="column is-6"> */}
            {/*        <Card> */}
            {/*            <UserChangePassword onSubmit={updatePassword} /> */}
            {/*        </Card> */}
            {/*    </div> */}
            {/*    <div className="column is-6"> */}
            {/*        <Card> */}
            {/*            <GitlabToken /> */}
            {/*        </Card> */}
            {/*    </div> */}
            {/* </div> */}
        </Layout>
    )
}
