import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import Progress from './componentsFinance/common/Progress'
import { useDeleteRequest } from '../hooks/request'
import { QQC_QUARTERLY_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import { useModal } from '../hooks/modal'
import QqcQuarterlyUpdate from './QqcQuarterlyUpdate'
import { getDate } from '../utils/date'

export default function QqcQuarterlyItem({ reportItem, index, onUpdate, onDelete }) {
    const rotationDelete = useDeleteRequest({
        url: QQC_QUARTERLY_DETAIL.replace('{id}', reportItem ? reportItem.id : ''),
    })
    const [showUpdateModal, hideUpdateModal] = useModal(
        <QqcQuarterlyUpdate report={reportItem} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    const [showMessage] = useMessage()

    async function deleteReport() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await rotationDelete.request()
        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onDelete()
    }

    return (

        <tr className="">
            <td className="has-text-centered">
                {index + 1}
            </td>
            <td className={cn('mt-3 is-centered')}>
                <NavLink className="" to={`/zero/qqc-quarterly-item/${reportItem.id}`}>
                    <span className="tag is-info is-light is-medium">
                        {getDate(reportItem.date)}
                    </span>
                </NavLink>
            </td>


            <td className={cn('has-text-centered')}>
                {reportItem.clientCount}
            </td>

            <td className={cn('has-text-centered')}>
                {reportItem.taskCount}
            </td>


            <td className="has-text-centered">
                {reportItem.taxCount}
            </td>
            <td className="has-text-centered">
                {reportItem.inpsCount}
            </td>
            <td className="has-text-centered">
                <span className="tag is-info is-light mb-2 is-medium">Ҳисоботлар жўнатилиши </span> <br />
                <span className="tag is-success is-light mb-2 is-medium">Солиқлар тўланиши    </span> <br />
                <span className="tag is-danger is-light mb-2 is-medium">ИНПС реестр жўнатолиши</span> <br />
            </td>

            <td className="has-text-centered">
                <div style={{ width: 150 }} className="mt-2">
                    <Progress value={reportItem.progress} text={`${reportItem.progress} %`} /> <br />
                    <Progress value={reportItem.progressTax} text={`${reportItem.progressTax} %`} /> <br />
                    <Progress value={reportItem.progressInps} text={`${reportItem.progressInps} %`} /> <br />
                </div>
            </td>

            <td className="has-text-centered">
                <i className="icon ion-md-trash" onClick={deleteReport} />
                <i className="icon ion-md-create" onClick={showUpdateModal} />

            </td>

        </tr>
    )
}
